* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* TODO define font base?????? */
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;*/
}

.outlet {
    display: flex;
    align-items: center;
    justify-content: stretch;
    max-width: 1700px;
    color: #fff !important;
}

@media (max-width: 1669px) {
    .outlet {
    max-width: 1400px;
    }
}

@media (max-width: 1350px) {
    .outlet {
    max-width: 1200px;
    }
}

@media (max-width: 1120px) {
    .outlet {
    max-width: 1000px;
    }
}


@media (max-width: 1010px) {
    .outlet {
    max-width: 900px;
    }
}

@media (max-width: 887px) {
    .outlet {
    max-width: 750px;
    }
}

@media (max-width: 760px) {
    .outlet {
    max-width: 650px;
    }
}

@media (max-width: 635px) {
    .outlet {
    max-width: 550px;
    }
}

@media (max-width: 535px) {
    .outlet {
    max-width: 450px;
    }
}

@media (max-width: 430px) {
    .outlet {
    max-width: 380px;
    }
}

@media (max-width: 365px) {
    .outlet {
    max-width: 350px;
    }
}

.rdt_Table {
    border: 1px solid rgba(0, 0, 0, .12);
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0 2px 5px
}

.rdt_TableHeadRow {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    color: rgba(120, 115, 137, 1);
}

.rdt_TableRow {
    border-bottom-width: 0 !important;
    background-color: transparent !important;
}

.rdt_TableRow:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.rdt_Pagination {
    border-top-width: 0 !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0 2px 5px;
    margin-top: -3px;
}

.apexcharts-legend.apx-legend-position-right.apexcharts-align-center {
    justify-content: center !important;
    align-items: stretch;
}

.apexcharts-legend.apx-legend-position-bottom.apx-legend-position-bottom.apexcharts-align-center {
    justify-content: left !important;
}

.apexcharts-legend-series span{
    display: flex;
}

.apexcharts-legend-text{
    flex: 1;
}

.apexcharts-legend-series.apexcharts-no-click{
    display: flex;
    flex-direction: row;
}

.apexcharts-legend.apexcharts-align-left.apx-legend-position-bottom {
    flex-direction: column;
}

.apexcharts-legend.apexcharts-align-right .apexcharts-legend-series, .apexcharts-legend.apexcharts-align-left .apexcharts-legend-series {
    display: flex !important;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #999999;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999999;
}
  